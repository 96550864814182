
import {computed, defineComponent, onMounted, ref} from "vue";
import * as Yup from "yup";
import UserService from "../../core/services/UserService";
import {Field, ErrorMessage, Form} from "vee-validate";
import {StepperComponent} from "@/assets/ts/components";
import FormErrorMessage from "@/components/base/form/FormErrorMessage.vue";
import AddressSelect from "@/components/base/select/AddressSelect.vue";
import BaseSelect from "@/components/base/select/BaseSelect.vue";
import {COMPANY_SIZE, INDUSTRY} from "@/core/config/Constant";
import {ModelAddress} from "@/core/entity/IAddress";

export default defineComponent({
  name: "VerifyUser",
  components: {BaseSelect, AddressSelect, FormErrorMessage, Field, Form, ErrorMessage},
  props: {
    token: {type: String}
  },
  setup() {
    const stepperObj = ref<StepperComponent | null>(null);
    const createAccountRef = ref<HTMLElement | null>(null);
    const currentStepIndex = ref(0);

    onMounted(() => {
      stepperObj.value = StepperComponent.createInsance(
        createAccountRef.value as HTMLElement
      );
    });
    const error = ref({error: false, message: ''})
    const state = ref<any>({
      company: '', password: '', firstName: '', lastName: '', token: '', timezone: '',
      companyRequest: {
        companyName: '', spaceName: 'Default Space', groupName: 'General', dashboardName: 'First Dashboard',
        spacePrivacy: 'PRIVATE',
        libraryName: 'First Library',
        spaceConfig: {work: true, metric: true, task: true, timer: false, documentLibrary: true},
        companyConfig: {
          website: '',
          phone: '',
          address: new ModelAddress(),
          size: 'SIZE_1_10',
          industry: 'I_1',
        }
      }
    });
    const registered = ref(false)


    const validate = Yup.object().shape({
      firstName: Yup.string().required().label("First Name"),
      lastName: Yup.string().required().label("Last Name"),
      password: Yup.string().required().label("Password"),
      companyName: Yup.string().required().label("Company Name"),
      website: Yup.string().required().label("Website"),
      phone: Yup.string().required().label("Phone"),
      size: Yup.string().required().label("Size"),
      industry: Yup.string().required().label("Industry"),
      spaceName: Yup.string().required().label("Workspace Name"),
      libraryName: Yup.string().required().label("First Library"),
      dashboardName: Yup.string().required().label("First Dashboard"),
    });

    const totalSteps = computed(() => {
      if (!stepperObj.value) {
        return;
      }

      return stepperObj.value.totatStepsNumber;
    });

    const previousStep = () => {
      if (!stepperObj.value) {
        return;
      }

      currentStepIndex.value--;

      stepperObj.value.goPrev();
    };
    const handleStep = () => {

      currentStepIndex.value++;

      if (!stepperObj.value) {
        return;
      }

      stepperObj.value.goNext();
    }

    return {
      COMPANY_SIZE,
      INDUSTRY,
      stepperObj,
      totalSteps,
      previousStep,
      handleStep,
      registered,
      validate,
      createAccountRef,
      currentStepIndex,
      state,
      error,
    }
  },
  methods: {

    async nextStep() {
      const form = this.$refs.registerForm as typeof Form
      form.validate();

      if (this.currentStepIndex === 0) {
        const firstName = await form.validateField("firstName")
        const lastName = await form.validateField("lastName")
        const password = await form.validateField("phone")
        if (!firstName.valid || !lastName.valid || !password.valid) {
          return
        }
      }
      if (this.currentStepIndex === 1) {
        const companyName = await form.validateField("companyName")
        const website = await form.validateField("website")
        const phone = await form.validateField("phone")
        const size = await form.validateField("size")
        const industry = await form.validateField("industry")
        if (!companyName.valid || !website.valid || !phone.valid || !size.valid || !industry.valid) {
          return
        }
      }
      if (this.currentStepIndex === 2) {
        const spaceName = await form.validateField("spaceName")
        if (!spaceName.valid) {
          console.log(spaceName.valid)
          return
        }
      }
      if (this.currentStepIndex === 3) {
        const lib = await form.validateField("libraryName")
        const dashboard = await form.validateField("dashboardName")
        if (!lib.valid || !dashboard.valid) {
          return
        }
      }

      this.currentStepIndex++;
      if (!this.stepperObj) {
        return;
      }

      this.stepperObj.goNext();
    },

    async formSubmit() {
      const form = this.$refs.registerForm as typeof Form
      form.validate();
      if (this.currentStepIndex === 3) {
        const lib = await form.validateField("libraryName")
        const dashboard = await form.validateField("dashboardName")
        if (!lib.valid || !dashboard.valid) {
          return
        }
      }
      this.state.token = this.token
      this.state.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const submitButton = this.$refs.submitButton as typeof HTMLButtonElement.prototype;
      submitButton.disabled = true
      submitButton?.setAttribute("data-kt-indicator", "on");
      UserService.register(this.state).then(status => {
        this.registered = status.success;
        this.error.error = !status.success;
        this.error.message = status.message;
      }).catch(eror => {
        const finalError = {};
        eror.data.errors.forEach(item => {
          finalError[item.field] = [item.message]
        })
        const form = this.$refs.registerForm as typeof HTMLFormElement.prototype
        form.setErrors(finalError);
      }).finally(() => {
        submitButton.disabled = false;
        submitButton?.removeAttribute("data-kt-indicator");
      })
    }
  }
})
